/*
 * 업무구분: 영업
 * 화 면 명: MSPFS315M
 * 화면설명: 계좌이체2015
 * 작 성 일: 2023.05.04
 * 작 성 자: 이성룡
 */
<template>
  <ur-page-container class="fts" :show-title="false" title="계좌이체" :topButton="true">
    
    <fs-alert-popup ref="alertPopup" :popupObj="pAlert" ></fs-alert-popup>
    <fs-header ref="fsHeader" :propObj="pHeaderObj" ></fs-header>

    <main class="fts-main">
      <ur-box-container direction="column" alignV="start">
				<div class="input-top">
          <div class="column w100p gap12">
            <div class="row w100p">
              <div class="left mr0 gap40 w100p">
                <div class="wrap-input row">
									<label class="emphasis"> 계좌번호</label>
									<mo-decimal-field numeric ref="bfcer_accn_no" v-model="lv_bfcer_accn_no.value" class="w130 input" :class="lv_bfcer_accn_no.error ? 'error' : ''" @keyup="fn_CommGroupNo('S', lv_bfcer_accn_no, $event)" @paste="fn_PasteCommGroupNo" mask="#######-###" />
									<mo-dropdown class="w340" ref="ed_acct_gb" :items="lv_acct_list" v-model="lv_ed_acct_gb.value" :disabled="lv_ed_acct_gb.disabled" placeholder=' ' />
									<mo-text-field v-model="lv_ed_cust_nm.value" class="w80" disabled />
									<mo-button primary @click="fn_FSS" v-show="lv_cust_fss.fssInfoExpsrEvntNo != '' && lv_cust_fss.fssInfoExpsrEvntNo != undefined"> 정보노출고객 </mo-button>
								</div>
								<div class="wrap-input row  flex-unset">
									<label class="emphasis"> 비밀번호 </label>
									<m-trans-key-input v-if="MOBILE" v-model="lv_secure_passv.value" ref="secure_passv" :disabled ="lv_secure_passv.disabled" v-model.trim="lv_secure_passv.value" :isClear="isClear" @masked-txt="fn_SetMaskedTxt"
										type="numberMax4" class="w80" :class="lv_secure_passv.error ? 'error' : ''" :start="'0'" :end="'-1'" dialog="Y" />
									<mo-text-field v-else v-model="lv_secure_passv.value" ref="secure_passv" :disabled ="lv_secure_passv.disabled" type="password" class="w80" :class="lv_secure_passv.error ? 'error' : ''" maxlength="4" @keyup="fn_Passwd" />

								</div>
              </div>
            </div>
            <div class="row w100p">
              <div class="left mr0 gap40">
                <div class="wrap-input row flex-unset">
									<label class="emphasis"> 유선구분 </label>
									<mo-radio-wrapper :items="rdo_ars_gb" v-model="lv_tel_use_yn" class="row" disabled />
								</div>
              </div>
              <div class="right w100p">
                <div class="wrap-button row">
									<!-- <mo-button @click="fn_test"> 테스트 </mo-button> -->
									<mo-button @click="fn_Init"> 초기화 </mo-button>
									<mo-button ref="SEARCH" primary @click="fn_Search"> 조회 </mo-button>
								</div>
              </div>
            </div>
          </div>
        </div>                                                                              
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
		  <fs-cust-info ref="fsCustInfo" :propObj="pCustInfo" @fs-cust-name="fn_SetEdCustName" ></fs-cust-info>
		  <fs-cust-info v-show="false" ref="fsTransCustInfo" :propObj="pTransCustInfo" @fs-cust-name="fn_SetTransEdCustName" ></fs-cust-info>
	  </ur-box-container>
      
      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title">
          <h2 class="table-title flex-sb mr0"> 이체출금사항 </h2>
        </div>
        <div class="wrap-table">
          <table class="table w50p th-wsN col2030">
            <tbody>
              <tr>
                <th>
                  <span class="emphasis"> 환매구분</span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown class="input-long" ref="redem_gb" :items="lv_commCode.code322" v-model="lv_redem_gb.value" :disabled="lv_redem_gb.disabled" :class="lv_redem_gb.error ? 'error' : ''" @input="fn_RedemGbOnChanged($event)" placeholder=' ' />
                  </div>
                </td>
                <th>
                  <span class="emphasis"> 금액(좌수)</span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-decimal-field class="input-long" numeric v-model="mae_paym_amt.value" maxlength='15' ref="mae_paym_amt" :class="mae_paym_amt.error ? 'error' : ''" :disabled="mae_paym_amt.disabled" @keyup="fn_RedemSearch($event)" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
		  </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title">
          <h2 class="table-title flex-sb mr0"> 인출가능금액 </h2>
        </div>
        <div class="wrap-table">
          <table class="table w50p th-wsN col2030">
            <tbody>
              <tr>
                <th>
                  <span> 전액 출금가능금액</span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" mask="number" v-model="mae_paym_possamt.value" :disabled="mae_paym_possamt.disabled" />
                  </div>
                </td>
                <th>
                  <span> 전액 출금가능좌수</span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" mask="number" v-model="mae_paym_possqty.value" :disabled="mae_paym_possqty.disabled" />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 출금금액 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" mask="number" v-model="mae_part_paym_possamt.value" :disabled="mae_part_paym_possamt.disabled" />
                  </div>
                </td>
                <th class="no-border-left"></th>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
		  </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title flex-sb align-end">
          <h2 class="table-title mr0"> 이체입금사항 </h2>
          <em class="colorR fs12">※ 계좌이체시 입금계좌번호는 브릿지(예수금)계좌만 가능합니다.</em>
        </div>
        <div class="wrap-table">
          <table class="table w50p th-wsN">
            <tbody>
              <tr>
                <th class="w20p">
                  <span class="emphasis">입금계좌번호</span>
                </th>
                <td>
                  <div class="wrap-input row flex-sb">
                    <div class="row gap6">
					  <mo-decimal-field  numeric ref="lv_trans_bfcer_accn_no" v-model="lv_trans_bfcer_accn_no.value" class="mw270" :class="lv_trans_bfcer_accn_no.error ? 'error' : ''" @focus="fn_FocusMaePaymAmt" @keyup="fn_CommGroupNo('T', lv_trans_bfcer_accn_no, $event)" @paste="fn_PasteTransCommGroupNo" mask="#######-###" />
					  <mo-dropdown class="mw270" ref="trans_ed_acct_gb" :items="lv_trans_acct_list" v-model="lv_trans_ed_acct_gb.value" :disabled="lv_trans_ed_acct_gb.disabled" placeholder=' ' />
                    </div>
                    <div class="row">
                      <mo-text-field class="mw130" v-model="lv_trans_ed_cust_nm.value"  disabled />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
		  </ur-box-container>

      <ur-box-container>
        <div class="wrap-button button-page-bottom row">
          <mo-button primary size="large" @click="fn_PrepareTransferAccount" :disabled="btn_disableTransferAccount"> 확인 </mo-button>
        </div>
      </ur-box-container>
    </main>

   <msp-fs-324p ref="popup324" :popupObj="pPopup324Obj" />
   <msp-fs-320p ref="popup320" :popupObj="pPopup320Obj" @fs-popup-callback="fn_Callback_320P" />
  </ur-page-container>
</template>
<script>
import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
import FSMessageUtil from '~/src/ui/fs/comm/FSMessageUtil'
import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
import FSCodeUtil from '~/src/ui/fs/comm/FSCodeUtil'
import FSInfoUtil from '~/src/ui/fs/comm/FSInfoUtil'
import FSHeader from '~/src/ui/fs/comm/FSHeader'
import FSAlertPopup from '~/src/ui/fs/comm/FSAlertPopup'
import FSCustInfo from '~/src/ui/fs/comm/FSCustInfo'
import FSDocForm from '@/ui/fs/comm_elst/FSDocFormUtil'
import MSPFS324P from '~/src/ui/fs/MSPFS324P'
import MSPFS320P from '~/src/ui/fs/MSPFS320P'

export default
	{
  	name:'MSPFS315M',
  	screenId:'MSPFS315M',
  	components:
  		{
		'fs-header':FSHeader,  
		'fs-alert-popup':FSAlertPopup,
		'fs-cust-info':FSCustInfo,
		'msp-fs-324p': MSPFS324P,
		'msp-fs-320p': MSPFS320P,
		},

  	created()
		{
		this.fn_SetCommCode()
		console.log("Parameter : ["+ this.$route.params.bfcer_accn_no+"]")
		let param = this.$route.params.bfcer_accn_no
		if ( param != undefined && FSCommUtil.gfn_trim(param) != '' && FSCommUtil.gfn_length(param) == 10 )
			{
			this.lv_bfcer_accn_no.value = param.substr(0,7) + '-' + param.substr(7,3)
			this.fn_CommAccnNoList('S')
			}
		},

	mounted()
		{
		this.fn_Init()
		this.$bizUtil.insSrnLog(this.$options.screenId)
		//FSCommUtil.gfn_focus(this.$refs['bfcer_accn_no'])
		},

  	data()
		{
		return {
			MOBILE:window.vue.getStore('deviceState').getters.getIsMobile,
			pHeaderObj:{title:'계좌이체', step:''},
			pAlert:{},
		  	lv_commCode:{},
		  	pCustInfo:{},
		  	lv_bfcer_accn_no:{value:'', disabled:true, error:false}, // 계좌번호
			lv_secure_passv:{}, // 비밀번호
			lv_tel_use_yn:'N', // 유선여부
			lv_ed_acct_gb:{}, // 계좌번호(우측) 계좌목록
			lv_acct_list:[], // 계좌번호(우측) Dropdown Dataset
			lv_ed_cust_nm:{}, // 고객명
			lv_cust_info:{}, // 고객정보

			lv_redem_gb:{value:'', disabled:true, error:false}, // 환매구분
			mae_paym_amt:{value:'', disabled:true, error:false}, // 금액(좌수)
			mae_paym_possamt:{}, // 전액출금가능금액 
			mae_paym_possqty:{}, // 전액출금가능좌수
			mae_part_paym_possamt:{value:'', disabled:true, error:false}, // 출금금액 

		  	pTransCustInfo:{},
		  	lv_trans_bfcer_accn_no:{value:'', disabled:true, error:false}, // 계좌번호(이체)
			lv_trans_ed_acct_gb:{}, // 계좌번호(이체) (우측) 계좌목록
			lv_trans_acct_list:[], // 계좌번호(이체) (우측) Dropdown Dataset
			lv_trans_ed_cust_nm:{}, // 고객명(이체)
			lv_trans_cust_info:{}, // 고객정보(이체)

			report:{}, // 전자문서(출금신청서)
			lv_cust_fss:{}, // 금감원 

			lv_masked_val:'', // 비밀번호 MaskText

			transFocusIn:'Y',
			pPopup324Obj:{},
			pPopup320Obj:{},

      		ds_search:
				{
				rlnm_cnf_no_encr:'',
				passbook_no:'',
				acct_seq_no:'',
				cust_no:''
				},

      		ds_trans:
				{
				rlnm_cnf_no_encr:'',
				passbook_no:'',
				acct_seq_no:'',
				cust_no:''
				},

			gv_adm_seq_no:0,
			gv_adm_eno:'',

			USER:this.getStore('fsStore').getters.getBasInfo.data,
			btn_disableTransferAccount:true,
		  	eai:FSCommUtil.gfn_eaiCommObj(),
			isClear: false,
		  	isActive:false
			}
		},
  
  	computed:
		{
    	rdo_ars_gb() // 유선구분
			{
		  	let tArr = []
		  	tArr.push({value:'N', text:'일반'})
		  	tArr.push({value:'Y', text:'유선'})
		  	return tArr
			}, 
		},

  	watch:
		{
		},
   
  	methods:
		{
		fn_Init()
			{
			console.log("초기화...")
			this.lv_bfcer_accn_no = FSCommUtil.gfn_dataSet(1, '', false, '', 'bfcer_accn_no')
			this.lv_secure_passv = FSCommUtil.gfn_dataSet(1, '', false, '', 'secure_passv')
			this.lv_ed_acct_gb = FSCommUtil.gfn_dataSet(1, '', true, '', 'ed_acct_gb')
			this.lv_ed_cust_nm = FSCommUtil.gfn_dataSet(1)
			this.lv_redem_gb = {value:'', disabled:true, error:false}
			this.tel_use_yn = 'N'
		  	this.pCustInfo = {}
			this.$refs.fsCustInfo.fn_Init()
			this.lv_acct_list = []
			this.lv_cust_info = {}

			this.mae_paym_amt = {value:'', disabled:true, error:false}
			this.mae_paym_possamt = {}
			this.mae_paym_possqty = {}
			this.mae_part_paym_possamt = {value:''}

		  	this.pTransCustInfo = {}
			this.$refs.fsTransCustInfo.fn_Init()
			this.lv_trans_acct_list = []
			this.lv_trans_cust_info = {}
			this.lv_trans_bfcer_accn_no = FSCommUtil.gfn_dataSet(1, '', false, '', 'trans_bfcer_accn_no')
			this.lv_trans_bfcer_accn_no.error = false
			this.lv_trans_ed_acct_gb = FSCommUtil.gfn_dataSet(1, '', true, '', 'trans_ed_acct_gb')
			this.lv_trans_ed_cust_nm = FSCommUtil.gfn_dataSet(1)

			this.lv_cust_fss = {}

			this.transFocusIn = 'Y'

			this.lv_masked_val = ''

			this.mae_paym_possamt.disabled = true
			this.mae_paym_possqty.disabled = true
			this.mae_part_paym_possamt.disabled = true

			this.btn_disableTransferAccount = true
			this.isClear = ! this.isClear ? true : false

			this.gv_adm_seq_no = 0
			this.gv_adm_eno = ''

			setTimeout(() => 1000)
			console.log("초기화 End...")
			},

		fn_SetCommCode() // F10570266 공통코드조회(AS-IS:selectComboList)
			{
         	let param =
				[
          			{'cId':'322', 'dayChkYn':'N'}
        		]

        	FSCodeUtil.commCodeList(this, param, this.fn_SetCommCodeResult)
			},

		fn_SetCommCodeResult(pResult)
			{
			this.lv_commCode = pResult
			this.lv_commCode.code322 = this.lv_commCode.code322.filter(c => c.value == '11' || c.value == '13')
			this.lv_commCode.code322.unshift({'value':'','text':''})
			if ( this.lv_commCode.code322 )
				{
				this.lv_redem_gb.disabled = false
				}
			},

		fn_CommGroupNo(pType, v, e)
			{
			if ( e.key == 'Control' || e.key == 'v' )
				{
				return
				}

			if ( pType == 'S' ) // 조회계좌
				{
				var accn = this.lv_bfcer_accn_no.value
				this.lv_bfcer_accn_no.error = false
				if ( FSCommUtil.gfn_length(accn) >= 10 )
					{
					if ( ! FSCommUtil.gfn_isNum(accn) )
						{
						FSCommUtil.gfn_validate(this, '계좌번호 형식에 맞지 않습니다.')
						this.lv_bfcer_accn_no.error = true
						FSCommUtil.gfn_focus(this.$refs['bfcer_accn_no'])
						return 
						}
					else
						{
						if ( FSCommUtil.gfn_isNum(e.key) || e.key == 'Delete' || e.key == 'Backspace' || this.MOBILE )
							{
							this.fn_CommAccnNoList(pType)
							}
						}
					}
				else
					{
					this.lv_secure_passv = FSCommUtil.gfn_dataSet(1, '', false, '', 'secure_passv')
					this.lv_ed_acct_gb = FSCommUtil.gfn_dataSet(1, '', true, '', 'ed_acct_gb')
					this.lv_ed_cust_nm = FSCommUtil.gfn_dataSet(1)
					this.lv_redem_gb = {value:'', disabled:true, error:false}
					this.tel_use_yn = 'N'
					this.pCustInfo = {}
					this.$refs.fsCustInfo.fn_Init()
					this.lv_acct_list = []
					this.lv_cust_info = {}

					this.mae_paym_amt = {value:'', disabled:true, error:false}
					this.mae_paym_possamt = {}
					this.mae_paym_possqty = {}
					this.mae_part_paym_possamt = {value:'', disabled:true, error:false}

					this.pTransCustInfo = {}
					this.$refs.fsTransCustInfo.fn_Init()
					this.lv_trans_acct_list = []
					this.lv_trans_cust_info = {}
					this.lv_trans_bfcer_accn_no = FSCommUtil.gfn_dataSet(1, '', false, '', 'trans_bfcer_accn_no')
					this.lv_trans_ed_acct_gb = FSCommUtil.gfn_dataSet(1, '', true, '', 'trans_ed_acct_gb')
					this.lv_trans_ed_cust_nm = FSCommUtil.gfn_dataSet(1)

					this.lv_cust_fss = {}

					this.transFocusIn = 'Y'
					this.lv_masked_val = ''

					this.mae_paym_possamt.disabled = true
					this.mae_paym_possqty.disabled = true
					this.mae_part_paym_possamt.disabled = true

					this.btn_disableTransferAccount = true

					this.gv_adm_seq_no = 0
					this.gv_adm_eno = ''
					}
				}
			else // 이체계좌
				{
				var accn = this.lv_trans_bfcer_accn_no.value
				this.lv_trans_bfcer_accn_no.error = false
				if ( FSCommUtil.gfn_length(accn) >= 10 ) 
					{
					if ( ! FSCommUtil.gfn_isNum(accn) )
						{
						FSCommUtil.gfn_validate(this, '계좌번호 형식에 맞지 않습니다.')
						this.lv_trans_bfcer_accn_no.error = true
						FSCommUtil.gfn_focus(this.$refs['trans_bfcer_accn_no'])
						return 
						}
					else
						{
						if ( FSCommUtil.gfn_isNum(e.key) || e.key == 'v' || e.key == 'Delete' || e.key == 'Backspace' || this.MOBILE )
							{
							this.fn_CommAccnNoList(pType)
							}
						}
					}
				else
					{
					this.pTransCustInfo = {}
					this.$refs.fsTransCustInfo.fn_Init()
					this.lv_trans_acct_list = []
					this.lv_trans_cust_info = {}
					this.lv_trans_ed_acct_gb = FSCommUtil.gfn_dataSet(1, '', true, '', 'trans_ed_acct_gb')
					this.lv_trans_ed_cust_nm = FSCommUtil.gfn_dataSet(1)
					}
				}
			},

		fn_PasteCommGroupNo(e)
			{
			var accn = (e.clipboardData || window.clipboardData).getData('text')
			accn = accn.replace(/[^0-9]/g, '')
			if ( accn.length == 10 )
				{
				this.lv_bfcer_accn_no.value = accn.substr(0,7) + '-' + accn.substr(7,3)
				this.fn_CommAccnNoList('S')
				}
			},

		fn_PasteTransCommGroupNo(e)
			{
			var accn = (e.clipboardData || window.clipboardData).getData('text')
			accn = accn.replace(/[^0-9]/g, '')
			if ( accn.length == 10 )
				{
				this.lv_trans_bfcer_accn_no.value = accn.substr(0,7) + '-' + accn.substr(7,3)
				this.fn_CommAccnNoList('T')
				}
			},

		fn_CommAccnNoList(pType) // F10570208 계좌목록조회(AS-IS:selectSY000000_AcctNoList)
			{
			if ( pType == 'S' )
				{
				FSInfoUtil.commInfo(this, 4, {bfcer_cust_no:FSCommUtil.gfn_substr(this.lv_bfcer_accn_no.value, 0, 7)}, function(res)
					{
					console.log("FSS RESULT ", res)
					if ( ! FSCommUtil.gfn_isNull(res.data.eaf1MDI0083_O_OOVO) )
						{
						this.lv_cust_fss = res.data.eaf1MDI0083_O_OOVO[0]
						}

					FSInfoUtil.commInfo(this, 2, {bfcer_cstgr_no:this.lv_bfcer_accn_no.value.replace(/[^0-9]/g, ''), input_check:'5', bfcer_accn_stat_cd:'Y'}, this.fn_CommAccnNoListResult)
					})
				}
			else
				{
				FSInfoUtil.commInfo(this, 2, {bfcer_cstgr_no:this.lv_trans_bfcer_accn_no.value.replace(/[^0-9]/g, ''), input_check:'1'}, this.fn_CommTransAccnNoListResult)
				}
			},

		fn_FSS()
			{
			let param =
				{
				event_no:this.lv_cust_fss.fssInfoExpsrEvntNo,
				event_cust_nm:this.lv_cust_fss.custNm,
				event_reg_no:FSCommUtil.gfn_substr(this.lv_cust_fss.custRrn, 0, 6) + '-' + FSCommUtil.gfn_substr(this.lv_cust_fss.custRrn, 6, 1) + '******',
				tel_no:this.lv_cust_fss.telno,
				hp_no:this.lv_cust_fss.celno,
				sago_cd:this.lv_cust_fss.fssInfoExpsrAcdtCd,
				sago_txt:this.lv_cust_fss.fssInfoExpsrAcdtNm,
				apnd_cmpy:this.lv_cust_fss.fssInfoExpsrRegCoNm,
				apnd_ymd:this.lv_cust_fss.regDtm,
				}

            this.pPopup324Obj = param
            this.$refs.popup324.fn_Open()
		  	},

		fn_CommAccnNoListResult(pResultData)
			{
			let t_data = pResultData.data.sy000000_O_00VO

			this.lv_acct_list = []
			this.lv_ed_acct_gb.disabled = true
			if ( t_data.length > 0 )
				{
				// console.log("@@@@@@@@@@@@ 계좌목록 : "+JSON.stringify(t_data))
				if ( t_data[0].fnd_cust_nm == '' || t_data[0].fnd_cust_nm == ' ' )
					{
					this.fn_AlertPopup(0, {content:'해당 고객은 당사 거래 종료로 인한 개인정보 접근제한 고객입니다.'})
					}
				else
					{
					t_data.some((item, index) =>
						{
						this.lv_acct_list.push({value:index, text:item.acct_no_nm, hiddenValue:item.bfcer_accn_no, pbk_tax_tp_gb:item.pbk_tax_tp_gb})
						if ( item.pbk_tax_tp_gb == '13' )
							{
							this.fn_AlertPopup(0, {content:'신연금 통장은 계좌이체 처리를 할 수 없습니다.'})
							return true
							}
						})
					
					this.lv_ed_acct_gb.value = 0
					this.lv_ed_acct_gb.disabled = false

					this.fn_CommCustInfo('S')
					}
				}
			else
				{
				this.lv_ed_cust_nm.value = ''
				this.fn_AlertPopup(0, {content:'검색할 계좌가 없습니다.'})
				}
			},

		fn_CommTransAccnNoListResult(pResultData)
			{
			let t_data = pResultData.data.sy000000_O_00VO

			this.lv_trans_acct_list = []
			this.lv_trans_ed_acct_gb.disabled = true
			if ( t_data.length > 0 )
				{
				if ( t_data[0].fnd_cust_nm == '' || t_data[0].fnd_cust_nm == ' ' )
					{
					this.fn_AlertPopup(0, {content:'해당 고객은 당사 거래 종료로 인한 개인정보 접근제한 고객입니다.'})
					}
				else
					{
					t_data.some((item, index) =>
						{
						if ( this.lv_trans_bfcer_accn_no.value == item.bfcer_apamt_accn_no.substr(0,7) + '-' + item.bfcer_apamt_accn_no.substr(7,3) )
							{
							this.lv_trans_acct_list.push({value:index, text:item.acct_no_nm, hiddenValue:item.bfcer_accn_no, pbk_tax_tp_gb:item.pbk_tax_tp_gb, bfcer_apamt_accn_no:item.bfcer_apamt_accn_no})
							}
						else
							{
							this.lv_trans_acct_list.push({value:index, text:item.acct_no_nm+'(브릿지)', hiddenValue:item.bfcer_accn_no, pbk_tax_tp_gb:item.pbk_tax_tp_gb, bfcer_apamt_accn_no:item.bfcer_apamt_accn_no})
							}

						this.lv_trans_bfcer_accn_no.value = item.bfcer_apamt_accn_no.substr(0,7) + '-' + item.bfcer_apamt_accn_no.substr(7,3)

						if ( item.pbk_tax_tp_gb == '13' )
							{
							this.fn_AlertPopup(0, {content:'신연금 통장은 계좌이체 처리를 할 수 없습니다.'})
							return true
							}
						})
					
					this.lv_trans_ed_acct_gb.value = 0

					this.fn_CommCustInfo('T')
					}
				}
			else
				{
				this.lv_trans_ed_cust_nm.value = ''
				this.fn_AlertPopup(0, {content:'검색할 계좌가 없습니다.'})
				}
			},

		fn_CommCustInfo(pType) // F10570272 고객정보조회(AS-IS:selectSY001000_CustInfo)
			{
			if ( pType == 'S' )
				{
				this.pCustInfo.custNo = FSCommUtil.gfn_substr(this.lv_bfcer_accn_no.value, 0, 7)
				this.$refs.fsCustInfo.fn_CustInfoSearch()
				}
			else
				{
				this.pTransCustInfo.custNo = FSCommUtil.gfn_substr(this.lv_trans_bfcer_accn_no.value, 0, 7)
				this.$refs.fsTransCustInfo.fn_CustInfoSearch()
				}
			},

		fn_SetEdCustName(val)
			{
			this.lv_ed_cust_nm.value = val
			if ( this.lv_bfcer_accn_no.value == '' || this.ds_search.rlnm_cnf_no_encr != '' )
				{
				this.ds_search.fnd_cust_nm = val
				}
			},

		fn_SetTransEdCustName(val)
			{
			this.lv_trans_ed_cust_nm.value = val
			if ( this.lv_trans_bfcer_accn_no.value == '' || this.ds_trans.rlnm_cnf_no_encr != '' )
				{
				this.ds_trans.fnd_cust_nm = val
				}
			},

		fn_FocusMaePaymAmt()
			{
			console.log("@@@@@@@@@@@ 입금계좌번호 FOCUS .transFocusIn : ["+this.transFocusIn+"]")
			console.log("\t 환매구분 : ["+ this.lv_redem_gb.value +"]")
       		if ( this.lv_redem_gb.value == '13' && this.transFocusIn == 'Y' ) // 정액환매
				{
				console.log("\t 정액환매 + FocusIn => 인출가능금액 조회 ...")
				this.mae_part_paym_possamt.value = this.mae_paym_amt.value

				this.eai.lv_vm = this
				this.eai.auth = 'S'
				this.eai.flag = 'REDEM_13_SEARCH'
				this.eai.commHeaderVO.eaiId = 'C391_F10570155_S'
				this.eai.params =
					{
					'proc_gb':'S0',
					'bfcer_accn_no':this.lv_acct_list[Number(this.lv_ed_acct_gb.value)].hiddenValue,
					'bfcer_rpus_sc_cd':this.lv_redem_gb.value,
					'bfcer_rpus_apl_amt':this.mae_paym_amt.value,
					'secure_passv':this.lv_secure_passv.value,
					'media':'',
					'callcenter_link_yn':'Y',
					'ars_pswn_yn':'',
					'comp_cd':'00001',
					'tr_code':'RP2015'
					}

				console.log("F10570155[3] Parameter : ", JSON.stringify(this.eai.params))
				FSServiceUtil.invokeInDirect(this.eai, this.fn_SearchResult)
				}
			},

		fn_Search() // F10570155 인출가능금액조회(AS-IS:selectRP251000_AccnNo)
			{
			console.log("@@@@@@@@@@@ 조회버튼 인출가능금액 조회...")
			var accn = this.lv_bfcer_accn_no.value.replace(/[^0-9]/g, '')
			if ( FSCommUtil.gfn_length(accn) == 0 )
				{
				FSCommUtil.gfn_validate(this, '계좌번호를 입력하십시오.')
				this.lv_bfcer_accn_no.error = true
				FSCommUtil.gfn_focus(this.$refs['bfcer_accn_no'])
				return
				}
			else if ( FSCommUtil.gfn_length(accn) < 10 )
				{
				FSCommUtil.gfn_validate(this, '계좌번호 입력오류입니다.')
				this.lv_bfcer_accn_no.error = true
				FSCommUtil.gfn_focus(this.$refs['bfcer_accn_no'])
				return
				}

			if ( this.MOBILE )
				{
				if ( FSCommUtil.gfn_length(this.lv_masked_val) == 0 )
					{
					FSCommUtil.gfn_validate(this, '비밀번호를 입력하십시오.')
					this.lv_secure_passv.error = true
					return
					}
				else if ( FSCommUtil.gfn_length(this.lv_masked_val) < 4 )
					{
					FSCommUtil.gfn_validate(this, '비밀번호 입력오류입니다.')
					this.lv_secure_passv.error = true
					return
					}
				}
			else
				{
				if ( FSCommUtil.gfn_length(this.lv_secure_passv.value) == 0 )
					{
					FSCommUtil.gfn_validate(this, '비밀번호를 입력하십시오.')
					this.lv_secure_passv.error = true
					FSCommUtil.gfn_focus(this.$refs['secure_passv'])
					return
					}
				else if ( FSCommUtil.gfn_length(this.lv_secure_passv.value) < 4 )
					{
					FSCommUtil.gfn_validate(this, '비밀번호 입력오류입니다.')
					this.lv_secure_passv.error = true
					FSCommUtil.gfn_focus(this.$refs['secure_passv'])
					return
					}
				}

			this.lv_secure_passv.error = false
			this.lv_bfcer_accn_no.error = false
			this.mae_paym_possamt.value = ''
			this.mae_paym_possqty.value = ''

			this.btn_disableTransferAccount = true
			this.mae_paym_amt.disabled = true // 금액(좌수)
			this.lv_redem_gb.disabled = true
			this.mae_part_paym_possamt.value = '' // 출금금액

			this.eai.lv_vm = this
			this.eai.auth = 'S'
			this.eai.flag = 'SEARCH_INIT'
			this.eai.commHeaderVO.eaiId = 'C391_F10570155_S'
			this.eai.params =
				{
			    'proc_gb':'S',
          		'bfcer_accn_no':this.lv_acct_list[Number(this.lv_ed_acct_gb.value)].hiddenValue,
          		'bfcer_rpus_sc_cd':'11',
          		'bfcer_rpus_apl_amt':this.mae_paym_amt.value,
          		'secure_passv':this.lv_secure_passv.value,
				'media':'',
			    'callcenter_link_yn':'N',
			    'ars_pswn_yn':'',
			    'comp_cd':'00001',
			    'tr_code':'RP2015'
				}

			this.transFocusIn = 'Y'
			console.log("F10570155[1] Parameter : ", JSON.stringify(this.eai.params))
        	FSServiceUtil.invokeInDirect(this.eai, this.fn_SearchResult)
			},

		fn_SearchResult(res)
			{
			console.log("조회결과 ...")
			// console.log("\t RESULT : ", res.data)
			console.log("\t 오류코드 : ", res.data.error_msg)
			console.log("\t 평가금액 : ", res.data.eval_amt)
			console.log("\t 금액(좌수) : ", res.data.paym_possamt)
			console.log("\t 전액출금가능금액 : ", res.data.paym_possamt)
			console.log("\t 전액출금가능좌수 : ", res.data.paym_possqty)
			console.log("\t 출금금액 : ", res.data.paym_possamt)

			if ( FSCommUtil.gfn_trim(res.data.error_msg) != '' )
				{
				if ( this.eai.flag == 'REDEM_13_SEARCH' )
					{
					console.log("@@@@@@@@@@@@@@@@@@@@@@ 정액환매 + Focus 인 경우 N 으로 변경 ...")
					this.transFocusIn = 'N'
					this.mae_part_paym_possamt.value = ''
					}

				this.fn_AlertPopup(0, {content:FSMessageUtil.commMessage([],res.data.error_msg)[res.data.error_msg.substring(0,6)]})
				return
				}

			if ( this.eai.flag == 'SEARCH_INIT' )
				{
				this.lv_redem_gb.value = ''
				}

		 	this.lv_redem_gb.disabled = false
       		var evalAmount = res.data.eval_amt
       		if ( Number(evalAmount) <= 0 )
				{
				this.fn_AlertPopup(0, {content:'출금가능금액이 없습니다 확인하십시오.'})
				return
				}

			if ( this.eai.flag == 'SEARCH_INIT' )
				{
				console.log("@@@@@@@@@@@ 조회... 환매구분 Combo OnChanged Event or 조회버튼 Click Event이면 인출가능금액 정보 모두 셋팅 ...")
				this.mae_paym_amt.value = res.data.paym_possamt
				this.mae_paym_possamt.value = res.data.paym_possamt
				this.mae_paym_possqty.value = res.data.paym_possqty
				this.mae_part_paym_possamt.value = res.data.paym_possamt
				}
			else if ( this.eai.flag == 'AUTO_SEARCH' )
				{
				console.log("@@@@@@@@@@@ 자동조회... 환매구분 Combo OnChanged Event or 조회버튼 Click Event이면 인출가능금액 정보 모두 셋팅 ...")

       			if ( this.lv_redem_gb.value == '13' ) // 정액환매
					{
					console.log("@@@@@@@@@@@ 자동조회... 정액 ...")
					this.mae_part_paym_possamt.value = res.data.paym_possamt
					}
				else
					{
					console.log("@@@@@@@@@@@ 자동조회... 전액 ...")
					this.mae_paym_amt.value = res.data.paym_possamt
					this.mae_paym_possamt.value = res.data.paym_possamt
					this.mae_paym_possqty.value = res.data.paym_possqty
					this.mae_part_paym_possamt.value = res.data.paym_possamt
					}
				}

			this.btn_disableTransferAccount = false
			this.mae_paym_amt.disabled = false // 금액(좌수)

       		if ( this.lv_redem_gb.value == '11' ) // 전액환매
				{
            	// 금액(좌수) 및 출금신청금액에 출금가능금액 셋팅
			  	this.mae_paym_amt.value = res.data.paym_possamt
			  	this.mae_part_paym_possamt.value = res.data.paym_possamt
			  	this.mae_paym_possamt.value = res.data.paym_possamt
			  	this.mae_paym_possqty.value = res.data.paym_possqty

				// 전액인경우 금액(좌수) 입력불가
				this.mae_paym_amt.disabled = true

            	// 출금가능금액이 없으면 처리불가
				this.btn_disableTransferAccount = Number(res.data.paym_possamt) <= 0
       			}
       		else if ( this.lv_redem_gb.value == '12' ) // 좌수환매
				{
			  	this.mae_part_paym_possamt.value = res.data.paym_possamt
       			}
       		else if ( this.lv_redem_gb.value == '13' ) // 정액환매
				{
				console.log("@@@@@@@@@@@ 일반 조회 결과 ...")
				this.mae_part_paym_possamt.value = res.data.paym_possamt
				}	
			},

		fn_RedemGbOnChanged()
			{
			this.transFocusIn = 'Y'
        	this.lv_redem_gb.error = false
        	if ( this.lv_redem_gb.value == '11' || this.lv_redem_gb.value == '14' )
				{
          		this.mae_paym_amt.value = ''
				this.mae_paym_amt.disabled = true 
				}
			else
				{
				this.mae_paym_amt.disabled = false
				}

			// 입력구분에 따른 인출가능금액조회
        	if ( this.lv_redem_gb.value == '11' ) // 전액환매
				{
				this.mae_paym_amt.disabled = true
				this.mae_paym_amt.value = ''

				// 자동호출. 입력구분에 따른 인출가능금액조회
				this.fn_AutoSearch()
				}
			else
				{
				this.mae_paym_amt.value = ''
				this.mae_paym_amt.disabled = false
				this.mae_part_paym_possamt.value = ''

				setTimeout(() => FSCommUtil.gfn_focus(this.$refs['mae_paym_amt']), 100)
        		}
			},

		fn_AutoSearch() // F10570155 인출가능금액조회(AS-IS:selectRP251000_AccnNo)
			{
			console.log("@@@@@@@@@@@@@@@ AUTO_SEARCH ...")
			if ( FSCommUtil.gfn_length(this.lv_redem_gb.value) == 0 || this.lv_acct_list.length == 0 || this.lv_redem_gb.value == '' )
				{
				return
				}

			if ( this.MOBILE )
				{
				if ( FSCommUtil.gfn_length(this.lv_masked_val) < 4 )
					{
					return
					}
				}
			else
				{
				if ( FSCommUtil.gfn_length(this.lv_secure_passv.value) < 4 )
					{
					return
					}
				}

        	if ( (this.lv_redem_gb.value == '12' || this.lv_redem_gb.value == '13' || this.lv_redem_gb.value == '15') && Number(this.mae_paym_amt.value) <= 0 )
				{
				console.log("@@@@@@@@@@@@@@@ AUTO_SEARCH 금액조회 안함 ...")
				return
				}

			this.mae_part_paym_possamt.value = ''

			this.eai.lv_vm = this
			this.eai.auth = 'S'
			this.eai.flag = 'AUTO_SEARCH'
			this.eai.commHeaderVO.eaiId = 'C391_F10570155_S'
			this.eai.params =
				{
			    'proc_gb':'S0',
          		'bfcer_accn_no':this.lv_acct_list[Number(this.lv_ed_acct_gb.value)].hiddenValue,
          		'bfcer_rpus_sc_cd':this.lv_redem_gb.value,
          		'bfcer_rpus_apl_amt':this.mae_paym_amt.value,
          		'secure_passv':this.lv_secure_passv.value,
				'media':'',
			    'callcenter_link_yn':'Y',
			    'ars_pswn_yn':'',
			    'comp_cd':'00001',
			    'tr_code':'RP2015'
				}

			console.log("F10570155[2] Parameter : ", JSON.stringify(this.eai.params))
        	FSServiceUtil.invokeInDirect(this.eai, this.fn_SearchResult)
			},

		fn_RedemSearch()
			{
			console.log("@@@@@@@@@@@@@@@ REDEM_SEARCH ...")
			this.transFocusIn = 'Y'
        	this.mae_paym_amt.error = false
        	if ( this.lv_redem_gb.value == '12' )
				{
				if ( Number(this.mae_paym_possqty.value) < Number(this.mae_paym_amt.value) ) // 전액 출금가능좌수 < 금액(좌수)
					{
					this.fn_AlertPopup(0, {content:'출금가능좌수를 초과하였습니다.'})
					this.mae_paym_amt.value = ''
					this.mae_part_paym_possamt.value = ''
					return
					}
				}
        	else if ( this.lv_redem_gb.value == '13' )
				{
				if ( Number(this.mae_paym_possamt.value) < Number(this.mae_paym_amt.value) ) // 전액 출금가능금액 < 금액(좌수)
					{
					this.fn_AlertPopup(0, {content:'출금가능금액을 초과하였습니다.'})
					this.mae_paym_amt.value = ''
					this.mae_part_paym_possamt.value = ''
					return
					}
				}
	    
			// 입력구분에 따른 인출가능금액조회
			this.fn_AutoSearch()
			},

		fn_PrepareTransferAccount()
			{
			console.log("계좌이체 입력값 검증 ...") 
			console.log("\t 환매구분 : ["+this.lv_redem_gb.value+"]")
			console.log("\t 금액(좌수) : ["+this.mae_paym_amt.value+"]")

			var accn = this.lv_bfcer_accn_no.value.replace(/[^0-9]/g, '')
			if ( FSCommUtil.gfn_length(accn) == 0 )
				{
				this.lv_bfcer_accn_no.error = true
				FSCommUtil.gfn_validate(this, '계좌번호를 입력하십시오.')
				FSCommUtil.gfn_focus(this.$refs['bfcer_accn_no'])
				return
				}
			else if ( FSCommUtil.gfn_length(accn) < 10 )
				{
				FSCommUtil.gfn_validate(this, '계좌번호 입력오류입니다.')
				this.lv_bfcer_accn_no.error = true
				FSCommUtil.gfn_focus(this.$refs['bfcer_accn_no'])
				return
				}

			var sIndex = Number(this.lv_ed_acct_gb.value)
			var sAccnNo = this.lv_acct_list[sIndex].hiddenValue
			var sTaxGb = this.lv_acct_list[sIndex].pbk_tax_tp_gb

			console.log("\t Source 계좌번호 : ["+ sAccnNo+"]")
			console.log("\t Source 세제구분 : ["+ sTaxGb+"]")

			if ( this.MOBILE )
				{
				if ( FSCommUtil.gfn_length(this.lv_masked_val) == 0 )
					{
					FSCommUtil.gfn_validate(this, '비밀번호를 입력하십시오.')
					this.lv_secure_passv.error = true
					return
					}
				else if ( FSCommUtil.gfn_length(this.lv_masked_val) < 4 )
					{
					FSCommUtil.gfn_validate(this, '비밀번호 입력오류입니다.')
					this.lv_secure_passv.error = true
					return
					}
				}
			else
				{
				if ( FSCommUtil.gfn_length(this.lv_secure_passv.value) == 0 )
					{
					FSCommUtil.gfn_validate(this, '비밀번호를 입력하십시오.')
					this.lv_secure_passv.error = true
					FSCommUtil.gfn_focus(this.$refs['secure_passv'])
					return
					}
				else if ( FSCommUtil.gfn_length(this.lv_secure_passv.value) < 4 )
					{
					FSCommUtil.gfn_validate(this, '비밀번호 입력오류입니다.')
					this.lv_secure_passv.error = true
					FSCommUtil.gfn_focus(this.$refs['secure_passv'])
					return
					}
				}

			if ( FSCommUtil.gfn_length(this.lv_redem_gb.value) != 2 )
				{
				this.lv_redem_gb.error = true
				FSCommUtil.gfn_validate(this, '환매구분 입력오류입니다.')
				FSCommUtil.gfn_focus(this.$refs['lv_redem_gb'])
				return
				}
			else
				{
				if ( ! (this.lv_redem_gb.value == '11' || this.lv_redem_gb.value == '14') )
					{
					if ( FSCommUtil.gfn_length(this.mae_paym_amt.value) == 0 || Number(this.mae_paym_amt.value) == 0 )
						{
						this.mae_paym_amt.error = true
						FSCommUtil.gfn_validate(this, '금액(좌수) 입력오류입니다.')
						FSCommUtil.gfn_focus(this.$refs['mae_paym_amt'])
						return
						}
					}
				}

			accn = this.lv_trans_bfcer_accn_no.value.replace(/[^0-9]/g, '')
			if ( FSCommUtil.gfn_length(accn) == 0 )
				{
				this.lv_trans_bfcer_accn_no.error = true
				FSCommUtil.gfn_validate(this, '이체계좌번호를 입력하십시오.')
				FSCommUtil.gfn_focus(this.$refs['lv_trans_bfcer_accn_no'])
				return
				}
			else if ( FSCommUtil.gfn_length(accn) < 10 )
				{
				this.lv_trans_bfcer_accn_no.error = true
				FSCommUtil.gfn_validate(this, '이체계좌번호 입력오류입니다.')
				FSCommUtil.gfn_focus(this.$refs['lv_trans_bfcer_accn_no'])
				return
				}

			var tIndex = Number(this.lv_trans_ed_acct_gb.value)
			var tAccnNo = this.lv_trans_acct_list[tIndex].hiddenValue
			var tTaxGb = this.lv_trans_acct_list[tIndex].pbk_tax_tp_gb

			console.log("\t Target 계좌번호 : ["+ tAccnNo+"]")
			console.log("\t Target 세제구분 : ["+ tTaxGb+"]")
			console.log("\t 예수금계좌 : ["+ this.lv_trans_acct_list[tIndex].bfcer_apamt_accn_no+"]")

			if ( sAccnNo.substr(0,7) != tAccnNo.substr(0,7) )
				{
				this.fn_AlertPopup(0, {content:'타인계좌로 이체가 불가능 합니다.'})
				return
				}

			if ( sAccnNo == tAccnNo )
				{
				this.fn_AlertPopup(0, {content:'이체할 계좌번호가 같습니다.'})
				return
				}

			if ( sTaxGb == '13' || tTaxGb == '13' )
				{
				this.fn_AlertPopup(0, {content:'신연금 통장은 계좌이체 처리를 할 수 없습니다.'})
				return
				}


			if ( FSCommUtil.gfn_length(this.mae_part_paym_possamt.value) == 0 || Number(this.mae_part_paym_possamt.value) == 0 )
				{
				this.fn_AlertPopup(0, {content:'출금금액이 결정되지 않았습니다. 금액(좌수) 확인버튼을 눌러주십시오.'})
				return
				}
			else if( FSCommUtil.gfn_isNull(this.gv_adm_seq_no) || this.gv_adm_seq_no == 0)
				{
				this.fn_AlertPopup(0, {cancel : true, confirm:true, confirmFunc:this.fn_TransferAccount, content:'출금금액은 ' + this.$commonUtil.numberWithCommas(this.mae_part_paym_possamt.value) + '원입니다. 계속 진행하시겠습니까?'})
				}
			else
				{
					this.fn_TransferAccount()
				}
			},

		fn_TransferAccount() // F10570227 계좌이체(AS-IS:insertRP201500_Service)
			{
			console.log("계좌이체 ...") 

			var sIndex = Number(this.lv_ed_acct_gb.value)
			var sAccnNo = this.lv_acct_list[sIndex].hiddenValue
			var tIndex = Number(this.lv_trans_ed_acct_gb.value)
			var tAccnNo = this.lv_trans_acct_list[tIndex].hiddenValue

			this.eai.lv_vm = this
			this.eai.auth = 'S'
			this.eai.commHeaderVO.eaiId = 'C391_F10570227_S'
			this.eai.commHeaderVO.reqCvrPblCd = 'I3'

			// 2023.05.24 승인자정보가 없으면 계좌이체가 이루어지지 않으니 책임자승인이 구현되기 전까지는 Hard-Coding
			this.eai.params =
				{
			    'tr_code':'RP2015',
			    'proc_tr_cd':'RP2015',
				'media':'', // (개별화면이 아닌) EAI Common Header에서 일괄처리
				'paym_cust_no':sAccnNo.substr(0,7), // 출금고객번호
				'paym_bfcer_cstgr_no':sAccnNo.substr(7,3), // 출금그룹일련번호
				'paym_acct_seq_no':sAccnNo.substr(10,3), // 출금계좌일련번호
          		'secure_passv':this.lv_secure_passv.value,
				'redem_gb':this.lv_redem_gb.value, // 환매구분
				'part_paym_possamt':this.lv_redem_gb.value == '11' ? this.mae_paym_possamt.value : '', // 일부(좌수)출금가능금액
				'paym_amt':this.mae_paym_amt.value, // 금액(좌수)
				'paym_cal_issue_yn':'N', // 지급계산서발급
				'recp_cust_no':tAccnNo.substr(0,7), // 입금고객번호
				'recp_bfcer_cstgr_no':tAccnNo.substr(7,3), // 입금그룹일련번호
				'recp_acct_seq_no':tAccnNo.substr(10,3), // 입금계좌일련번호
				'sup_id':this.gv_adm_eno, // 승인자 
				'aprv_sno':this.gv_adm_seq_no, // 승인번호
			    'tel_use_yn':'N',
			    'ars_pswn_yn':'',
			    'ENCALGO':'SEED'
				}

			console.log("F10570227[2] Parameter : ", JSON.stringify(this.eai.params))
        	FSServiceUtil.invokeInDirect(this.eai, this.fn_TransferAccountResult)
			},

		fn_TransferAccountResult(res)
			{
			console.log("계좌이체 결과  ...")
			console.log("\t RESULT : ", res.data)
			console.log("\t 오류코드 : ", res.data.error_msg)
			console.log("\t USER : ", this.USER)

			if ( res.data.error_msg == '050013' )
				{
				let paramData = {
					adm_proc_gb    : res.data.adm_proc_gb   , // 승인처리구분 : 0 - 승인요청 1 - 승인응답
					trt_ymd        : res.data.trt_ymd       , // 처리일
					bfcer_fof_cd   : res.data.bfcer_fof_cd  , // 처리지점
					bfcer_fof_nm   : res.data.bfcer_fof_nm  , // 처리지점명
					aprv_req_sno   : res.data.aprv_req_sno  , // 승인일련번호
					req_tr_code    : res.data.req_tr_code   , // 처리요청 tr_code
					req_tr_code_nm : res.data.req_tr_code_nm, // 처리화면명
					rqstr_eno      : res.data.rqstr_eno     , // 요청사원번호
					rqstr_eno_nm   : res.data.rqstr_eno_nm  , // 요청사원명
					message        : res.data.message       , // 메시지
					fileSeq        : res.data.fileSeq       , // 캡쳐 파일일련번호
					orgFileNm      : res.data.orgFileNm     , // 캡쳐 원본파일명
					fileChk        : res.data.fileChk       , // 캡쳐 파일체크
					gv_adm_seq_no  : this.gv_adm_seq_no 	, // 승인일련번호
					gv_adm_eno     : this.gv_adm_eno    	, // 승인사원번호
					adm_eno        : res.data.adm_eno         // 승인자 리스트
				}

				console.log("책임자 승인 paramData : ", paramData)

				this.pPopup320Obj = paramData
				this.$refs.popup320.fn_Open()
				return
				}

			if ( FSCommUtil.gfn_trim(res.data.error_msg) != '' && res.data.error_msg != '809905' )
				{
				this.fn_AlertPopup(0, {content:FSMessageUtil.commMessage([],res.data.error_msg)[res.data.error_msg.substring(0,6)]})
				return
				}

			if( res.data.aprv_req_sno != 0 )
				{
				let adm_eno_list = []
				let adm_eno = {
					staf_eno       : res.data.staf_eno      ,	  // 책임자 사원번호
					staf_nm        : res.data.staf_nm       ,	  // 책임자 명
					fof_nm         : res.data.fof_nm        ,	  // 책임자 지점명
					auth_grp_cd_nm : res.data.auth_grp_cd_nm,    // 책임자 그룹명
					user_ip        : res.data.user_ip       ,	  // 책임자 IP
				}
				if ( !FSCommUtil.gfn_isNull(res.data.staf_eno) ) {
					adm_eno_list.push(adm_eno)
				}

				let paramData = {
					adm_proc_gb    : res.data.adm_proc_gb   , // 승인처리구분 : 0 - 승인요청 1 - 승인응답
					trt_ymd        : res.data.trt_ymd       , // 처리일
					bfcer_fof_cd   : res.data.bfcer_fof_cd  , // 처리지점
					bfcer_fof_nm   : res.data.bfcer_fof_nm  , // 처리지점명
					aprv_req_sno   : res.data.aprv_req_sno  , // 승인일련번호
					req_tr_code    : res.data.req_tr_code   , // 처리요청 tr_code
					req_tr_code_nm : res.data.req_tr_code_nm, // 처리화면명
					rqstr_eno      : res.data.rqstr_eno     , // 요청사원번호
					rqstr_eno_nm   : res.data.rqstr_eno_nm  , // 요청사원명
					message        : res.data.message       , // 메시지
					fileSeq        : res.data.fileSeq       , // 캡쳐 파일일련번호
					orgFileNm      : res.data.orgFileNm     , // 캡쳐 원본파일명
					fileChk        : res.data.fileChk       , // 캡쳐 파일체크
					gv_adm_seq_no  : this.gv_adm_seq_no 	, // 승인일련번호
					gv_adm_eno     : this.gv_adm_eno    	, // 승인사원번호
					adm_eno_list   : adm_eno_list    		  // 승인자 리스트
				}

				console.log("책임자 승인 paramData : ", paramData)

				this.pPopup320Obj = paramData
				this.$refs.popup320.fn_Open()
				}
			else
				{
				this.report = res.data
				// this.report.bfcer_cust_no = this.lv_bfcer_accn_no.value
				this.fn_AlertPopup(9, {confirm:true, confirmFunc:this.fn_Report, confirmData:this.report, content:['정상처리되었습니다.']})
				this.fn_Init()
				}
			},

		fn_Callback_320P(res)
			{
			console.log("책임자승인 Callback ...")
			console.log("\t RESULT : ", res)

			this.gv_adm_seq_no = res.gv_adm_seq_no
			this.gv_adm_eno = res.gv_adm_eno

			if ( res.successYN == 'N' )
				{
				this.fn_AlertPopup(0, {content:'책임자 승인요청이 취소되었습니다.'})
				}
			else
				{
				this.fn_PrepareTransferAccount()
				}
			},

		fn_Passwd()
			{
			this.lv_secure_passv.error = false
			if ( FSCommUtil.gfn_length(this.lv_secure_passv.value) >= 4 )
				{
				this.fn_Search()
				}
			},

      	fn_SetMaskedTxt(val)
	  		{
			this.lv_masked_val = val
			if ( this.lv_masked_val.length >= 4 )
				{
				this.fn_Search()
				}
      		},

      	fn_AlertPopup(type, o)
			{
        	if ( ! FSCommUtil.gfn_isNull(o) )
				{
				FSInfoUtil.initAlertPopObj(type, this.pAlert, o)
				}

			this.$refs.alertPopup.fn_Open()
			},

		fn_HistoryBack()
			{
			this.$router.go(-1)
			},

    	async fn_Report()
			{
			console.log("계좌이체 전자문서  ...")
			console.log("\t REPORT : ", this.report);
			let formList = []
			let FS000027 = {'formId':'FS000027','formdata':this.lv_redem_gb.value == '11' ? '전액인출' : ''} // 출금신청서(+전자서명)
			let o =
				{
				'bfcer_cust_no':this.report.bfcer_cust_no,
				'frm_issnc_ymd':this.report.frm_issnc_ymd,
				'issnc_hstr_sno':this.report.issnc_hstr_sno,
				'bfcer_busn_trt_id':'RP2015',
				'rprt_frm_no':'0113'
				}

			var sIndex = Number(this.lv_ed_acct_gb.value)
			/**
			let o =
				{
				'cust_nm':this.$refs.fsCustInfo.lv_cust_info.fnd_cust_nm, // 고객명	
				'rname_no':this.$refs.fsCustInfo.lv_cust_info.rlnm_cnf_no_encr.substr(0,6) + '-' + this.$refs.fsCustInfo.lv_cust_info.rlnm_cnf_no_encr.substr(6,1) + '******', // 주민번호
				'canc_yn2':'N', // 취소여부
				'paym_acct_no':this.lv_acct_list[sIndex].hiddenValue, // 출금계좌번호
				'numtokor':this.toKorAmount(this.mae_paym_amt.value), // 신청금액(한글)
				'atoi':this.mae_paym_amt.value, // 신청금액
				'formdata':this.lv_redem_gb.value == '11' ? '전액인출' : '', // 적요
				'bank_acct_no':this.lv_trans_acct_list[sIndex].hiddenValue, // 입금계좌번호
				'bank_acct_nm':this.$refs.fsCustInfo.lv_cust_info.fnd_cust_nm, // 고객명	
				'btrans_bank_nm':'', // 은행명(대체계좌주)	
				'agntNm':this.$refs.fsCustInfo.lv_cust_info.agent_nm, // 대리인명
				'dept_nm':this.USER == undefined ? '' : FSCommUtil.gfn_isNull_replace(this.USER.userDeptNm, '') // 담당 고객플라자
				}
			**/

			console.log("전자서식 Parameter : " + JSON.stringify(o))

			FS000027.params =
				{
			  	reportParam:JSON.stringify(o),
			  	reportUrl:'reportCO070500_Info'
				}

			formList.push(FS000027)
			FSInfoUtil.commReportInfo(this, formList)
			},

		toKorAmount(amount)
			{
			let result = ''
			let dArr = ['영', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구']
			let uArr = ['', '십', '백', '천', '만', '십', '백', '천', '억', '십', '백', '천', '조', '십']

			let s = amount.toString()
			let sLen = s.length
			for ( var vIndex=0; vIndex<sLen; vIndex++ )
				{
				var d = parseInt(s.charAt(vIndex))
				var u = uArr[sLen-vIndex-1]

				if ( vIndex == sLen-1 && d == 1 && sLen != 1 )
					{
					result += '일'
					}
				else if ( d != 0 )
					{
					result += dArr[d] + u
					}
				else if ( vIndex == sLen-5 ) // 십만단위는 '만'을 붙이지 않음
					{
					result += '만'
					}
				}

			return result
			},

		validate()
			{
		  	this.$addSnackbar({text: '필수입력 항목입니다.', error: true});
			},

			fn_test() {
				this.report = {}
				this.report.bfcer_cust_no = this.lv_bfcer_accn_no.value
				this.fn_AlertPopup(9, {confirm:true, confirmFunc:this.fn_Report, confirmData:this.report, content:['정상처리되었습니다.']})
			},

    	toggle01:function(){this.isActive = !this.isActive;},
		}
	};
</script>
<style scoped>
</style>
