/*
 * 업무구분: 고객
 * 화 면 명: MSPFS324P
 * 화면설명: 금감원개인정보노출
 * AS-IS  : SY001800P
 * 작 성 일: 2023.06.14
 * 작 성 자: 이태흥
 */
<template>

    <mo-modal class="fts-modal medium" ref="modal" title="금감원개인정보노출">
      <template>
        <div class="wrap-modalcontents">
          <div class="wrap-desc red type02 mt0 mb10">
            <p> 본인확인을 철저히 하시기 바랍니다. </p>
          </div>
          <div class="wrap-table">
            <table class="table col-type col2377">
              <tr>
                <th> 사건번호 </th>
                <td> {{ stc_event_no }} </td>
              </tr>
              <tr>
                <th> 노출자명 </th>
                <td> {{ stc_event_cust_nm }} </td>
              </tr>
              <tr>
                <th> 주민등록번호 </th>
                <td> {{ stc_event_reg_no }} </td>
              </tr>
              <tr>
                <th> 전화번호 </th>
                <td> {{ stc_tel_no }} </td>
              </tr>
              <tr>
                <th> 휴대폰 </th>
                <td> {{ stc_hp_no }} </td>
              </tr>
              <tr>
                <th> 사고코드 </th>
                <td> {{ stc_sago_cd }} </td>
              </tr>
              <tr>
                <th> 등록회사 </th>
                <td> {{ stc_apnd_cmpy }} </td>
              </tr>
              <tr>
                <th> 등록일 </th>
                <td> {{ stc_apnd_ymd }} </td>
              </tr>
            </table>
          </div>
        </div>
      </template>
      <template slot="action">
        <div class="wrap-button button-page-bottom row">
          <mo-button point size="large" @click="fn_Close()">닫기</mo-button>
        </div>
      </template>
    </mo-modal>

</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'


  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPFS324P",
    screenId: "MSPFS324P",
    props: {
      popupObj: {type:Object, default:null},
    },    
    components: {

    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {},
    mounted() {
      this.$bizUtil.insSrnLog('MSPFS324P')
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {

        // 조회결과
        stc_event_no: '',
        stc_event_cust_nm: '',
        stc_event_reg_no: '',
        stc_tel_no: '',
        stc_hp_no: '',
        stc_sago_cd: '',
        stc_apnd_cmpy: '',
        stc_apnd_ymd: '',


      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {

      modal() {
        return this.$refs.modal
      },

    },
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {
      
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {

      fn_Open() {
        this.fn_Init()

        setTimeout(() => {
          if( FSCommUtil.gfn_isNull(this.popupObj) == false && FSCommUtil.gfn_isNull(this.popupObj.event_no) == false ) {
            this.stc_event_no        = this.popupObj.event_no
            this.stc_event_cust_nm   = this.popupObj.event_cust_nm
            this.stc_event_reg_no    = this.popupObj.event_reg_no
            this.stc_sago_cd         = this.popupObj.sago_cd + ' ' + this.popupObj.sago_txt
            this.stc_apnd_cmpy       = this.popupObj.apnd_cmpy
            this.stc_apnd_ymd        = `${FSCommUtil.gfn_dateReplace(1, this.popupObj.apnd_ymd)} ${this.popupObj.apnd_ymd.substr(8,2)}:${this.popupObj.apnd_ymd.substr(10,2)}`  // yyyy-mm-dd hh:mm

            //집전화 마스킹
            if(FSCommUtil.gfn_length(this.popupObj.tel_no) > 4) {
              this.stc_tel_no        = this.popupObj.tel_no.slice(0, -4) + "****"
            } else {
              this.stc_tel_no        = "*".repeat(this.popupObj.tel_no.length)
            }

            //핸드폰번호 마스킹
            if(FSCommUtil.gfn_length(this.popupObj.hp_no) > 4) {
              this.stc_hp_no         = this.popupObj.hp_no.slice(0, -4) + "****"
            } else {
              this.stc_hp_no         = "*".repeat(this.popupObj.hp_no.length)
            }
          }
          this.modal.open()
        }, 10)
      },

      fn_Close() {
        this.modal.close()
      },


      /******************************************************************************
       * Function명 : fn_Init
       * 설명       : 화면 초기화
       ******************************************************************************/
      fn_Init() {
        console.log('[MSPFS324P] fn_Init...')

        // 조회결과
        this.lv_search_list = {}
      },
      

    }    
  }
</script>
<style scoped>
</style>